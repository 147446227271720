var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading.className !== '')?_c('div',{class:[
  ( _obj = {
    'sk-fade-in': _vm.fadeIn === 'full' && !_vm.noFadeIn,
    'sk-fade-in-half-second': _vm.fadeIn === 'half' && !_vm.noFadeIn,
    'sk-fade-in-quarter-second': _vm.fadeIn === 'quarter' && !_vm.noFadeIn
  }, _obj[_vm.className] = !!_vm.className, _obj ),
  'sk-spinner',
  _vm.loading.className
],style:(_vm.style)},_vm._l((_vm.loading.divCount),function(_,index){return _c('div',{key:index})}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }